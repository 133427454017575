
import { defineComponent, PropType, ref, watch } from "vue";
import { useStore } from "vuex";

const CFormImageUpload = defineComponent({
  name: "CFormImageUpload",
  emits: ["update:modelValue"],
  props: {
    size: { type: String, default: "156px" },
    photos: { type: Array as PropType<{ id: number; photo: string }[]>, default: () => [] },
    isDisabled: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    modelValue: { type: Object as PropType<File[]>, required: true },
  },
  setup(props, { emit }) {
    const store = useStore();

    const files = ref<{ id?: number; photo?: string; file?: File }[]>(JSON.parse(JSON.stringify(props.photos)));
    const fileUpload = ref<HTMLInputElement | null>(null);
    const supportedImageTypes = ["jpg", "jpeg", "png"];
    const dragging = ref(false);

    function addFile(file: File & { photo?: "" }) {
      file?.name?.trim().replaceAll(" ", "-");
      files.value.push({ photo: file.photo || URL.createObjectURL(file), file: file });
    }

    function handleDrag(event: any) {
      dragging.value = false;

      for (const item of event.dataTransfer.items) {
        if (item.kind === "file") {
          const file: File = item.getAsFile();
          if (!file.type || !supportedImageTypes.includes(file.type.split("/")[1])) {
            store.commit("setToast", [
              {
                summary: `Formatos suportados: ${supportedImageTypes.join(", ")}`,
                severity: "error",
              },
            ]);

            continue;
          }

          addFile(file);
        }
      }
    }

    function handleChange(event: FileUploadEvent) {
      if (!props.isMultiple) files.value = [];

      for (const file of event?.target?.files || []) {
        const currentFile = file.file || file;
        if (!currentFile.name) continue;

        addFile(file);
      }

      emit("update:modelValue", files.value);
    }

    function handleRemove(index: number) {
      files.value.splice(index, 1);

      setTimeout(() => {
        if (!props.isMultiple && fileUpload.value) fileUpload.value.click();
      }, 10);

      emit("update:modelValue", files.value);
    }

    if (props.modelValue?.length) handleChange({ target: { files: props.modelValue } });

    watch(
      () => props.photos,
      (photos) => {
        files.value = JSON.parse(JSON.stringify(photos));
        emit("update:modelValue", files.value);
      }
    );

    emit("update:modelValue", files.value);

    return {
      files,
      fileUpload,
      supportedImageTypes,
      dragging,
      handleChange,
      handleRemove,
      handleDrag,
    };
  },
});

export type FileUploadEvent = { target: { files: any[] } };

export default CFormImageUpload;
